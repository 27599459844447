import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";

// wangeditor重写video元素，主要是为了可以加上自定义的属性
import "./utils/wangeditor-video-module";

Vue.config.productionTip = false;

Vue.use(ElementUI);

new Vue({
  render: (h) => h(App),
}).$mount("#app");
