<template>
  <div style="border: 1px solid #ccc">
    <Toolbar
      style="border-bottom: 1px solid #ccc"
      :editor="editor"
      :defaultConfig="toolbarConfig"
      :mode="mode"
    />
    <Editor
      style="height: 500px; overflow-y: hidden"
      v-model="html"
      :defaultConfig="editorConfig"
      :mode="mode"
      @onCreated="onCreated"
      @onChange="onChange"
      @onDestroyed="onDestroyed"
      @onMaxLength="onMaxLength"
      @onFocus="onFocus"
      @onBlur="onBlur"
      @customAlert="customAlert"
    />
  </div>
</template>

<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { DomEditor, Boot } from "@wangeditor/editor";

export default {
  components: { Editor, Toolbar },
  //接收v-model的值
  // model是组件里的关键属性，model里定义的prop属性值，要和prop里接收的参数值相对应，
  // 然后在进行事件操作时，将event里事件$emit即可。这样就可以使用自定义的v-model了
  props: {
    value: String,
  },
  //  随便命名事件，对应下面$emit即可
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      editor: null,
      html: "",
      toolbarConfig: {
        excludeKeys: ["group-video"],
        insertKeys: {
          index: 23, // 插入的位置，基于当前的 toolbarKeys
          keys: ["cfitInsertVideo", "cfitUploadVideo"],
        },
      },
      editorConfig: {
        placeholder: "请输入内容...",
        MENU_CONF: {
          uploadImage: {
            // 自定义上传
            // async customUpload(file: File, insertFn: InsertFnType) {  // TS 语法
            async customUpload(file, insertFn) {
              console.log("file", file, insertFn);
              let formData = new formData();
              // file是对应后台的参数名
              formData.append("file", file);

              // JS 语法
              // file 即选中的文件
              // 自己实现上传，并得到图片 url alt href
              // 最后插入图片
              //   insertFn(url, alt, href);
            },
          },
        },
      },
      mode: "default", // or 'simple'
    };
  },
  watch: {
    value: {
      handler(val) {
        console.log("watch val", val);
        if (val !== this.html) {
          this.html = val === null ? "" : val;
        }
      },
    },
  },
  methods: {
    onCreated(editor) {
      //将对象设置为不可扩展,同时将对象的所有自有属性都设置为不可配置(包括Symbol值的属性)。也就是说,不能给对象添加新的属性和方法,也不能删除现有的属性和方法、不能修改现有属性和方法的配置。但如果对象的属性和方法是可写的,那该属性和方法仍然可以修改。
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
      console.log(this.editor.getConfig());
      const originVal = this.value == null ? "" : this.value;
      this.editor.setHtml(originVal);
    },
    onChange(editor) {
      var toolbar = DomEditor.getToolbar(this.editor);
      const curToolbarConfig = toolbar.getConfig();
      console.log("toolbarKeys", curToolbarConfig.toolbarKeys); // 当前菜单排序和分组

      console.log("onChange", editor.children);
      this.$emit("change", editor.getHtml());
    },
    onDestroyed(editor) {
      console.log("onDestroyed", editor);
    },
    onMaxLength(editor) {
      console.log("onMaxLength", editor);
    },
    onFocus(editor) {
      console.log("onFocus", editor);
    },
    onBlur(editor) {
      console.log("onBlur", editor);
    },
    customAlert(info, type) {
      window.alert(`customAlert in Vue demo\n${type}:\n${info}`);
    },
    customPaste(editor, event, callback) {
      console.log("ClipboardEvent 粘贴事件对象", event);
      // const html = event.clipboardData.getData('text/html') // 获取粘贴的 html
      // const text = event.clipboardData.getData('text/plain') // 获取粘贴的纯文本
      // const rtf = event.clipboardData.getData('text/rtf') // 获取 rtf 数据（如从 word wsp 复制粘贴）

      // 自定义插入内容
      editor.insertText("xxx");

      // 返回 false ，阻止默认粘贴行为
      event.preventDefault();
      callback(false); // 返回值（注意，vue 事件的返回值，不能用 return）

      // 返回 true ，继续默认的粘贴行为
      // callback(true)
    },
    registerVideoMenu() {
      const _this = this;
      // 插入视频
      class MyInsertVideoButtonMenu {
        // JS 语法

        constructor() {
          this.title = "插入视频"; // 自定义菜单标题
          this.iconSvg =
            '<svg viewBox="0 0 1024 1024"><path d="M981.184 160.096C837.568 139.456 678.848 128 512 128S186.432 139.456 42.816 160.096C15.296 267.808 0 386.848 0 512s15.264 244.16 42.816 351.904C186.464 884.544 345.152 896 512 896s325.568-11.456 469.184-32.096C1008.704 756.192 1024 637.152 1024 512s-15.264-244.16-42.816-351.904zM384 704V320l320 192-320 192z"></path></svg>'; // 可选
          this.tag = "button";
        }

        // 获取菜单执行时的 value ，用不到则返回空 字符串或 false
        getValue(editor) {
          console.log(editor);
          return " hello ";
        }

        // 菜单是否需要激活（如选中加粗文本，“加粗”菜单会激活），用不到则返回 false
        isActive(editor) {
          console.log(editor);
          return false;
        }

        // 菜单是否需要禁用（如选中 H1 ，“引用”菜单被禁用），用不到则返回 false
        isDisabled(editor) {
          console.log(editor);
          return false;
        }
        // 点击菜单时触发的函数
        exec(editor, value) {
          if (this.isDisabled(editor)) return;
          console.log("cfitInsertVideo", value);

          const node = {
            type: "video",
            poster: "",
            src: "https://live.apt.ac.cn/%E7%A9%BA%E3%82%82%E9%A3%9B%E3%81%B9%E3%82%8B%E3%81%AF%E3%81%9A%EF%BC%8F%E3%82%B9%E3%83%94%E3%83%83%E3%83%84%EF%BC%88Cover%EF%BC%89.mp4",
            children: [{ text: "" }],
            videoId: 5,
            width: 200,
            height: 100,
          };
          _this.editor.insertNode(node);
        }
      }

      // 上传视频
      class MyUploadVideoButtonMenu {
        constructor() {
          this.title = "上传视频"; // 自定义菜单标题
          this.iconSvg =
            '<svg viewBox="0 0 1056 1024"><path d="M805.902261 521.819882a251.441452 251.441452 0 0 0-251.011972 246.600033 251.051015 251.051015 0 1 0 502.023944 8.823877 253.237463 253.237463 0 0 0-251.011972-255.42391z m59.463561 240.001647v129.898403h-116.701631v-129.898403h-44.041298l101.279368-103.504859 101.279368 103.504859z"></path><path d="M788.254507 0.000781H99.094092A98.663439 98.663439 0 0 0 0.001171 99.093701v590.067495a98.663439 98.663439 0 0 0 99.092921 99.092921h411.7549a266.434235 266.434235 0 0 1-2.186448-41.815807 275.843767 275.843767 0 0 1 275.180024-270.729042 270.650955 270.650955 0 0 1 103.504859 19.834201V99.093701A101.51363 101.51363 0 0 0 788.254507 0.000781zM295.054441 640.747004V147.507894l394.146189 246.600033z"></path></svg>'; // 可选
          this.tag = "button";
        }

        // 获取菜单执行时的 value ，用不到则返回空 字符串或 false
        getValue(editor) {
          console.log(editor);
          return " hello ";
        }

        // 菜单是否需要激活（如选中加粗文本，“加粗”菜单会激活），用不到则返回 false
        isActive(editor) {
          console.log(editor);
          return false;
        }

        // 菜单是否需要禁用（如选中 H1 ，“引用”菜单被禁用），用不到则返回 false
        isDisabled(editor) {
          console.log(editor);
          return false;
        }

        // 点击菜单时触发的函数
        exec(editor, value) {
          if (this.isDisabled(editor)) return;
          console.log("cfitUploadVideo", _this, value);
        }
      }

      const menuInsertVideoConf = {
        key: "cfitInsertVideo", // 定义 menu key ：要保证唯一、不重复（重要）
        factory() {
          return new MyInsertVideoButtonMenu(); // 把 `YourMenuClass` 替换为你菜单的 class
        },
      };

      const menuUploadVideoConf = {
        key: "cfitUploadVideo", // 定义 menu key ：要保证唯一、不重复（重要）
        factory() {
          return new MyUploadVideoButtonMenu(); // 把 `YourMenuClass` 替换为你菜单的 class
        },
      };

      const module = {
        menus: [menuInsertVideoConf, menuUploadVideoConf],
      };
      Boot.registerModule(module);
    },
  },
  mounted() {
    this.registerVideoMenu();
    setTimeout(() => {
      // const node = {
      //   type: "video",
      //   poster: "",
      //   src: "https://live.apt.ac.cn/%E7%A9%BA%E3%82%82%E9%A3%9B%E3%81%B9%E3%82%8B%E3%81%AF%E3%81%9A%EF%BC%8F%E3%82%B9%E3%83%94%E3%83%83%E3%83%84%EF%BC%88Cover%EF%BC%89.mp4",
      //   children: [{ text: "" }],
      //   videoId: 5,
      //   width: 200,
      //   height: 100,
      // };
      // this.editor.insertNode(node);
    }, 5000);
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style>
.w-e-full-screen-container {
  z-index: 2000 !important;
}
</style>
