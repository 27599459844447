/**
 * wangeditor重写video元素，主要是为了可以加上自定义的属性。如，我们在这里添加了videoId
 * 参考代码：https://github.com/wangeditor-team/wangEditor/tree/master/packages/video-module/src/module
 * 参考文档：https://www.wangeditor.com/v5/development.html#%E5%AE%9A%E4%B9%89%E6%96%B0%E5%85%83%E7%B4%A0
 */
import { DomEditor, Boot, SlateTransforms } from "@wangeditor/editor";
import $ from "dom7";
import { h } from "snabbdom";

// 定义节点数据结构
// 参考：https://github.com/wangeditor-team/wangEditor/blob/master/packages/video-module/src/module/custom-types.ts#L4
// const VideoElement = {
//   type: "video",
//   src: "xxx.mp4",
//   poster: "",
//   width: "",
//   height: "",
//   children: [{ text: "" }],
//   videoId: "",
// };

// 定义插件
function withVideo(editor) {
  const { isVoid, normalizeNode } = editor;
  const newEditor = editor;

  // 重写 isVoid
  newEditor.isVoid = (elem) => {
    const { type } = elem;

    if (type === "video") {
      return true;
    }

    return isVoid(elem);
  };

  // 重写 normalizeNode
  newEditor.normalizeNode = ([node, path]) => {
    const type = DomEditor.getNodeType(node);

    // ----------------- video 后面必须跟一个 p header blockquote -----------------
    if (type === "video") {
      // -------------- video 是 editor 最后一个节点，需要后面插入 p --------------
      const isLast = DomEditor.isLastNode(newEditor, node);
      if (isLast) {
        SlateTransforms.insertNodes(newEditor, DomEditor.genEmptyParagraph(), {
          at: [path[0] + 1],
        });
      }
    }

    // 执行默认的 normalizeNode ，重要！！！
    return normalizeNode([node, path]);
  };

  // 返回 editor ，重要！
  return newEditor;
}
// 把插件withVideo注册到wangEditor
Boot.registerPlugin(withVideo);

function genSizeStyledIframeHtml(iframeHtml, width, height) {
  const $iframe = $(iframeHtml);
  $iframe.attr("width", width);
  $iframe.attr("height", height);
  return $iframe[0].outerHTML;
}

// 渲染"video"元素到编辑器
function renderVideo(elemNode, children, editor) {
  const {
    src = "",
    poster = "",
    width = "auto",
    height = "auto",
    videoId = "",
  } = elemNode;

  // 是否选中
  const selected = DomEditor.isNodeSelected(editor, elemNode);

  let vnode;
  const _style = {
    backgroundImage:
      "linear-gradient(45deg,#eee 25%,transparent 0,transparent 75%,#eee 0,#eee),linear-gradient(45deg,#eee 25%,#fff 0,#fff 75%,#eee 0,#eee)",
    backgroundPosition: "0 0,10px 10px",
    backgroundSize: "20px 20px",
    border: "1px dashed var(--w-e-textarea-border-color)",
    borderRadius: "5px",
    margin: "10px auto 0",
    padding: "10px 0",
    textAlign: "center",
  };
  if (src.trim().indexOf("<iframe ") === 0) {
    // 增加尺寸样式
    const iframeHtml = genSizeStyledIframeHtml(src, width, height);

    // iframe 形式，第三方视频
    vnode = (
      <div
        // className="w-e-textarea-video-container"
        style={_style}
        data-selected={selected ? "true" : ""} // 标记为 选中
        innerHTML={iframeHtml} // 内嵌第三方 iframe 视频
      ></div>
    );
  } else {
    // 其他，mp4 格式
    const videoVnode = (
      <video poster={poster} videoId={videoId} controls>
        <source src={src} type="video/mp4" />
        {`Sorry, your browser doesn't support embedded videos.\n 抱歉，浏览器不支持 video 视频`}
      </video>
    );
    // @ts-ignore 添加尺寸
    if (width !== "auto") videoVnode.data.width = width;
    // @ts-ignore
    if (height !== "auto") videoVnode.data.height = height;

    vnode = (
      <div
        // classname="w-e-textarea-video-container"
        style={_style}
        data-selected={selected ? "true" : ""} // 标记为 选中
      >
        {videoVnode}
      </div>
    );
  }

  // 【注意】void node 中，renderElem 不用处理 children 。core 会统一处理。

  const containerVnode = h(
    "div",
    {
      props: {
        contentEditable: false,
      },
      on: {
        mousedown: (e) => e.preventDefault(),
      },
    },
    vnode
  );

  return containerVnode;
}

// 注册 renderVideo 到 wangEditor
// 先定义renderVideo配置
const renderVideoConf = {
  type: "video", // 和 elemNode.type 一致
  renderElem: renderVideo,
};
// 然后把renderVideoConf注册到wangEditor
Boot.registerRenderElem(renderVideoConf);

// 生成"video"元素的HTML
function videoToHtml(elemNode) {
  const {
    src = "",
    poster = "",
    width = "auto",
    height = "auto",
    videoId = "",
  } = elemNode;
  let res = '<div data-w-e-type="video" data-w-e-is-void>\n';

  if (src.trim().indexOf("<iframe ") === 0) {
    // iframe 形式
    const iframeHtml = genSizeStyledIframeHtml(src, width, height);
    res += iframeHtml;
  } else {
    // 其他，mp4 等 url 格式
    res += `<video poster="${poster}" controls="true" width="${width}" height="${height}" videoId="${videoId}"><source src="${src}" type="video/mp4"/></video>`;
  }
  res += "\n</div>";

  return res;
}
// 注册videoToHtml到wangEditor
// 先定义videoToHtml配置
const videoToHtmlConf = {
  type: "video",
  elemToHtml: videoToHtml,
};
// 然后注册到wangEditor
Boot.registerElemToHtml(videoToHtmlConf);

function genVideoElem(
  src,
  poster,
  width = "auto",
  height = "auto",
  videoId = ""
) {
  return {
    type: "video",
    src,
    poster,
    width,
    height,
    videoId,
    children: [{ text: "" }], // void 元素有一个空 text
  };
}

// 解析HTML字符串，生成"video"元素
function parseHtml(elem) {
  const $elem = $(elem);
  let src = "";
  let poster = "";
  let width = "auto";
  let height = "auto";
  let videoId = "";
  // <iframe> 形式
  const $iframe = $elem.find("iframe");
  if ($iframe.length > 0) {
    width = $iframe.attr("width") || "auto";
    height = $iframe.attr("height") || "auto";
    src = $iframe[0].outerHTML;
    return genVideoElem(src, poster, width, height, videoId);
  }

  // <video> 形式
  const $video = $elem.find("video");
  src = $video.attr("src") || "";
  if (!src) {
    if ($video.length > 0) {
      const $source = $video.find("source");
      src = $source.attr("src") || "";
    }
  }
  width = $video.attr("width") || "auto";
  height = $video.attr("height") || "auto";
  poster = $video.attr("poster") || "";
  videoId = $video.attr("videoId") || "";
  return genVideoElem(src, poster, width, height, videoId);
}

// 注册parseHtml到wangEditor
// 先定义parseHtml配置
const parseHtmlConf = {
  selector: 'div[data-w-e-type="video"]',
  parseElemHtml: parseHtml,
};
// 然后把parseHtmlConf注册到wangEditor
Boot.registerParseElemHtml(parseHtmlConf);
