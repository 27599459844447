<template>
  <div class="tc-vod-upload">
    <el-upload
      ref="uploadVideo"
      class="upload-demo"
      drag
      action=""
      accept=".mp4"
      :show-file-list="false"
      :http-request="httpUploadFile"
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <div class="el-upload__tip" slot="tip">只能上传mp4文件</div>
    </el-upload>
  </div>
</template>
<script>
import TcVod from "vod-js-sdk-v6";
import request from "../utils/request";

// APPID：1256278744
// SecretId:AKID81QTRxu0B5Y8cJ8rU3o1A4vgZL1chnR6
// SecretKey:oWYbbvCe4653wyJrsxmbLTWykPTTPKB3
export default {
  data() {
    return {
      uploader: null,
    };
  },
  async created() {
    this.getSignature();
    // const signature = await this.test();
    // console.log("signature0000", signature);
  },
  methods: {
    test() {
      return request({
        url: "/video/few0301/getSignature",
        // url: "/vodtest/sign",
        method: "get",
      });
    },
    async getSignature() {
      console.log("signature1");
      const signature = await this.test();
      console.log("signature2", signature);
      return signature.TxSignature || "";
    },
    httpUploadFile(params) {
      console.log(params);
      this.$refs.uploadVideo.clearFiles();
      this.doUpload(params.file);
    },
    doUpload(file) {
      console.log("file", file);
      const _this = this;
      // 通过 import 引入的话，new TcVod(opts) 即可
      // new TcVod.default(opts) 是 script 引入 的用法
      const tcVod = new TcVod({
        getSignature: _this.getSignature, // 前文中所述的获取上传签名的函数
      });

      this.uploader = tcVod.upload({
        mediaFile: file, // 媒体文件（视频或音频或图片），类型为 File
      });

      // 视频上传进度
      this.uploader.on("media_progress", function (info) {
        console.log("media_progress", (info.percent * 100).toFixed(0)); // 进度
      });

      // 视频上传完成时
      this.uploader.on("media_upload", function (info) {
        console.log("media_upload", info);
      });

      //   // 封面上传完成时
      //   this.uploader.on("cover_upload", function (info) {
      //     uploaderInfo.isCoverUploadSuccess = true;
      //   });
      //   // 封面上传进度
      //   this.uploader.on("cover_progress", function (info) {
      //     uploaderInfo.coverProgress = info.percent;
      //   });

      this.uploader.done().then(function (doneResult) {
        // deal with doneResult
        console.log("doneResult", doneResult);
      });

      // 回调结果说明
      // type doneResult = {
      //   fileId: string,
      //   video: {
      //     url: string
      //   },
      //   cover: {
      //     url: string
      //   }
      // }
    },
  },
};
</script>
