import axios from "axios";
const service = axios.create({
  baseURL: "http://172.16.41.179:8081",
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },
  timeout: 60000,
});

service.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] =
      "Bearer eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6IjRmMmRkNjg0LWMyMjctNDk3MS04MzZjLTIxZWNiM2U5MWM0OSJ9._C2MbY8IgUIiQJao6XEXphOVxj4PFC863H7XsPLyCIoL1FWnrPql_kkzH0ChFnZQxfupF7rYDQH7ycbgIo-uUw";
    return config;
  },
  (error) => {
    console.log("error=====", error);
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (res) => {
    const code = res.data.msgCode || 200;
    const msg = res.data.message || "errrr";
    if (code == 401) {
      console.log("登陆过期");
    } else if (code == 500) {
      console.log(msg);
      return Promise.reject(new Error(msg));
    } else if (code != 200) {
      console.log(msg);
      return Promise.reject("error");
    } else {
      return res.data;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default service;
